import { cloneDeep, partial, findIndex, find, get } from 'lodash';
import { checkAllRemindersForUnassignedRoleErrors } from 'src/common/create-edit-modal/create-edit-modal.helpers';
import { isClientRequest } from 'src/common/tasks.helper';

const fakeIDs = {
  subtasks: [],
};

export const NAME_ERROR_STRING = 'Please enter a task name';
export const NAME_LENGTH_ERROR_STRING = 'Task name exceeds allowed length';
export const DATE_ERROR_STRING = 'Repeating tasks need at least one date';
export const CLIENT_ERROR_STRING = 'This template requires a client';

export function getFakeID(field) {
  if (!fakeIDs[field]) fakeIDs[field] = [];
  let result;
  for (let i = 0; !result; i++) {
    if (!fakeIDs[field][i]) {
      fakeIDs[field][i] = true;
      result = '_' + i;
    }
  }

  return result;
}

export function removeFakeID(field, id) {
  delete fakeIDs[field][id];
}

export const emptyListTypes = {
  dates: {
    recurring_month_day: null,
  },
  subtasks: {
    budgeted_hours: 0,
    dates: [],
    reminders: [],
    type: 'subtask',
    relationships: {
      assigned_to: [],
      notification_recipients: [],
    },
    name: '',
  },
  reminders: {
    relationships: {
      notification_recipients: [],
    },
  },
};

export const emptyTask = {
  name: '',
  budgeted_hours: 0,
  client_id: null,
  resolution_case_id: null,
  relationships: {
    assigned_to: [],
    notification_recipients: [],
  },
  description: undefined,
  recurrence: {
    interval_unit: null,
    interval: null,
  },
  dates: [],
  subtasks: [],
  reminders: [],
  status_id: 'NOSTATUS',
};

export function validateTaskTemplate(taskTemplate) {
  let errors = [];
  if (!taskTemplate.name && !taskTemplate.title) {
    errors.push(`Please enter a ${taskTemplate.type === 'client_request' ? 'request' : 'task'} name`);
  } else if (taskTemplate.name?.trim() === '' || taskTemplate.title?.trim() === '') {
    errors.push("Task name can't be empty space");
  }
  if (taskTemplate.descriptionRef?.current.getHTML().length > 90000) {
    errors.push('Description exceeds allowed length');
  }
  if (!listIsValid(taskTemplate.subtasks || [], taskTemplateSubtaskIsValid)) {
    errors.push('Please check the subtask(s) for validation errors');
  }
  return errors.length > 1
    ? 'Please make sure that all required fields have been completed'
    : errors.length === 1
    ? errors[0]
    : '';
}

export function validateTaskName(name) {
  if (!name || !name.trim()) {
    return NAME_ERROR_STRING;
  } else if (name.length > 255) {
    return NAME_LENGTH_ERROR_STRING;
  } else {
    return '';
  }
}

export function validateTask({
  task,
  prevTask,
  isOneOff,
  invalidAssignees,
  numOfClients,
  hasRecurrenceError,
  fullRoleOptions,
}) {
  const taskNameError = validateTaskName(task.name);
  if (taskNameError) {
    return taskNameError;
  } else if (!listIsValid(task.dates, dateIsValid)) {
    return 'Please check the date(s) for validation errors';
  } else if (prevTask && prevTask.id && !reminderAssigneesAreValid(task, prevTask)) {
    return 'Task reminder assignee missing access to client';
  } else if (!isOneOff && !listIsValid(task.reminders, reminderIsValid)) {
    return 'Please check reminders for validation errors';
  } else if (!isOneOff && !listIsValid(task.subtasks, subtaskIsValid)) {
    return 'Please check the subtask(s) for validation errors';
  } else if (!repeatIsValid(task)) {
    return DATE_ERROR_STRING;
  } else if (!filesAreValid(task)) {
    return 'Please check the attached files';
  } else if (checkForClientRequest(task) && (isOneOff ? !task.client_id : !numOfClients)) {
    return CLIENT_ERROR_STRING;
  } else if (!!invalidAssignees?.length) {
    return 'Please resolve client access';
  } else if (hasRecurrenceError) {
    return 'Please check the recurrence for errors';
  } else if (checkAllRemindersForUnassignedRoleErrors(task, fullRoleOptions)) {
    return 'Please check the reminders for errors';
  } else {
    return '';
  }
}

function repeatIsValid(task) {
  return (
    (!task.recurrence?.interval && !task.recurrence?.interval_unit) ||
    (task.recurrence.interval > 0 && task.recurrence.interval_unit)
  );
}

function reminderAssigneesAreValid(task, oldTask) {
  return !task.reminders.some((r, i) => {
    r.relationships.notification_recipients.length !==
      oldTask.reminders[i].relationships.notification_recipients.length;
  });
}

function listIsValid(list, isValid) {
  return list.length === list.filter(isValid).length;
}

export function dateIsValid(date) {
  //All fields empty or required fields are filled
  return (!date.date_type_id && !date.date && !date.relative) || (date.date_type_id && (date.date || date.relative));
}

export function templateReminderIsValid(reminder) {
  return (
    (reminder.relationships.notification_recipients.length > 0 || !reminder.selectAssigneeNow) &&
    reminder.relationships.date_type_id
  );
}

export function reminderIsValid(reminder) {
  //All fields empty or required fields are filled
  return (
    (reminder.relationships.notification_recipients.length === 0 && !reminder.relationships.date_type_id) ||
    (reminder.relationships.notification_recipients.length > 0 && reminder.relationships.date_type_id)
  );
}

export function taskTemplateSubtaskIsValid(subtask) {
  const validDesc = subtask.descriptionRef?.current.getHTML().length < 90000;

  if (isClientRequest(subtask)) {
    return subtask.title && subtask.title.trim() && validDesc;
  } else {
    return subtask.name && subtask.name.trim() !== '' && validDesc;
  }
}

function filesAreValid(task) {
  const isInvalidFile = file => !!file.client_id && file.client_id !== task.client_id;
  let invalidFiles = get(task, 'relationships.files', []).filter(isInvalidFile);
  task.subtasks.forEach(
    subtask => (invalidFiles = [...invalidFiles, ...get(subtask, 'relationships.files', []).filter(isInvalidFile)])
  );
  return invalidFiles.length === 0;
}

export function fileIsValid(file, clientId) {
  return !!file.client_id && file.client_id !== clientId;
}

export function subtaskIsValid(subtask) {
  //All fields empty or required fields are filled
  if (isClientRequest(subtask)) {
    return (
      !!subtask.name?.trim() &&
      (!subtask.due_date || dateIsValid(subtask.due_date)) &&
      (!subtask.reminder_data ||
        !Object.keys(subtask.reminder_data).length ||
        (subtask.reminder_data.interval && subtask.reminder_data.expiration_interval_days))
    );
  }
  return (
    !!subtask.name?.trim() && listIsValid(subtask.dates, dateIsValid) && listIsValid(subtask.reminders, reminderIsValid)
  );
}

//Prepares the task object to be posted
//Removes skip_assignees, and validates the required properties
export function slimTaskTemplateForApi(task, clearExistingIds) {
  const postTask = cloneDeep(task);
  if (clearExistingIds) {
    delete postTask.created_by;
    delete postTask.created_at;
    delete postTask.updated_at;
    delete postTask.updated_by;
  }
  postTask.name = postTask.name.trim();
  postTask.description = postTask.descriptionRef?.current
    ? postTask.descriptionRef.current.getHTML()
    : postTask.description;
  delete postTask.descriptionRef;
  postTask.subtasks = postTask.subtasks.map(subtask => {
    const subtaskClone = { ...subtask };
    if (isClientRequest(subtaskClone)) {
      return slimClientRequestSubtask(subtaskClone);
    } else {
      return slimSubtask(subtaskClone, true);
    }
  });
  postTask.dates = postTask.dates.filter(partial(filterFalseyProperties, ['date_type_id']));
  postTask.reminders = postTask.reminders.filter(obj => {
    return (
      filterFalseyProperties(['count', 'interval', 'interval_unit'], obj) &&
      filterFalseyProperties(['date_type_id'], obj.relationships)
    );
  });
  if (postTask.relationships.files) postTask.relationships.files = slimFilesList(postTask.relationships.files);
  return postTask;
}

function slimSubtask(subtaskClone, isTemplate) {
  subtaskClone.name = subtaskClone.name.trim();
  if (isTemplate && subtaskClone.descriptionRef) {
    subtaskClone.description = subtaskClone.descriptionRef.current.getHTML();
    delete subtaskClone.descriptionRef;
  } else if (typeof subtaskClone.description === 'function') {
    subtaskClone.description = subtaskClone.description();
  }
  delete subtaskClone.subtasks;
  delete subtaskClone.skip_assignees;
  subtaskClone.dates = subtaskClone.dates ? subtaskClone.dates.filter(d => d.date || d.relative) : [];
  if (subtaskClone.relationships.files)
    subtaskClone.relationships.files = slimFilesList(subtaskClone.relationships.files);
  subtaskClone.budgeted_hours = parseFloat(subtaskClone.budgeted_hours) || 0;
  return subtaskClone;
}

function slimClientRequestSubtask(crSubtaskClone) {
  crSubtaskClone.title = crSubtaskClone.title.trim();
  if (crSubtaskClone.descriptionRef?.current) {
    crSubtaskClone.message = crSubtaskClone.descriptionRef.current.getHTML();
    delete crSubtaskClone.descriptionRef;
  }
  if (crSubtaskClone.relationships.files)
    crSubtaskClone.relationships.files = slimFilesList(crSubtaskClone.relationships.files);
  return crSubtaskClone;
}

function slimFilesList(files) {
  return files.map(file => ({ id: file.id, delete: file.delete }));
}
function addUnderscoreToId(id) {
  return id && id[0] === '_' ? id : '_' + id;
}
//Adds an underscore to the begining of all task ids
//Used when a template is applied so that ids no longer point to the template task
export function resetIds(task) {
  const temp = cloneDeep(task);
  temp.id = addUnderscoreToId(temp.id);
  temp.dates = temp.dates?.map(d => {
    const date = cloneDeep(d);
    if (date.relative && date.relative.base_task_id)
      date.relative.base_task_id = addUnderscoreToId(date.relative.base_task_id);
    return date;
  });
  if (temp.subtasks) temp.subtasks = temp.subtasks.map(resetIds);
  return temp;
}

export function filterFalseyProperties(arr, obj) {
  return arr.reduce((bool, prop) => {
    if (!bool) return bool;
    return (
      obj[prop] !== undefined &&
      obj[prop] !== null &&
      obj[prop] !== '' &&
      (Array.isArray(obj[prop]) ? obj[prop].length !== 0 : true)
    );
  }, true);
}

export function hasRelativeDate(rootTask, taskId) {
  return (
    rootTask.dates.some(d => d.relative && d.relative.base_task_id === taskId) ||
    rootTask.subtasks.some(task => {
      if (!isClientRequest(task)) {
        return task.dates.some(d => d.relative && d.relative.base_task_id === taskId);
      }
      return;
    })
  );
}

export function hasDependentReminder(task, dateTypeId) {
  return task.reminders.some(r => r.relationships.date_type_id === dateTypeId);
}

//deletes the date and all dates relative to it
export function deleteDate({ rootTask, dateTypeId, dateParentTaskId, deletingTask }) {
  let task = cloneDeep(rootTask);

  //remove self
  if (rootTask.id === dateParentTaskId) {
    const index = findIndex(rootTask.dates, d => d.date_type_id === dateTypeId);
    if (~index) task.dates.splice(index, 1);
  } else {
    const parentTaskIndex = findIndex(task.subtasks, t => t.id === dateParentTaskId);
    if (~parentTaskIndex) {
      const parentTask = task.subtasks[parentTaskIndex];
      let dateIndex = -1;
      if (!isClientRequest(parentTask)) {
        dateIndex = findIndex(parentTask.dates, d => d.date_type_id === dateTypeId);
      }
      if (~dateIndex) {
        parentTask.dates.splice(dateIndex, 1);
        task.subtasks.splice(parentTaskIndex, 1, parentTask);
      } else if (isClientRequest(parentTask)) {
        if (parentTask.due_date) {
          parentTask.due_date = null;
        } else {
          parentTask.dates = [];
        }
        task.subtasks.splice(parentTaskIndex, 1, parentTask);
      }
    }
  }

  return deleteDependentDates({ rootTask: task, dateTypeId, dateParentTaskId, deletingTask });
}

function filterTeammembers(assignedTeamMembers, validTeamMembers) {
  return assignedTeamMembers
    ? assignedTeamMembers.filter(
        assignee => assignee.type === 'role' || validTeamMembers.find(tm => tm.id === assignee.id)
      )
    : [];
}

export function removeInvalidTeamAssignments(rootTask, validTeammembers) {
  let task = cloneDeep(rootTask);

  if (!task.relationships) {
    task.relationships = {};
  }
  if (task.relationships.team_members) {
    task.relationships.team_members = filterTeammembers(task.relationships.team_members, validTeammembers);
  } else {
    task.relationships.assigned_to = filterTeammembers(task.relationships.assigned_to, validTeammembers);
  }

  if (task.reminders) {
    task.reminders = task.reminders.map(reminder => {
      const temp = cloneDeep(reminder);
      temp.relationships.notification_recipients = filterTeammembers(
        temp.relationships.notification_recipients,
        validTeammembers
      );
      return temp;
    });
  }

  if (task.subtasks) {
    task.subtasks = task.subtasks.map(s => removeInvalidTeamAssignments(s, validTeammembers));
  }

  return task;
}

export function deleteDependentDates({ rootTask, dateTypeId, dateParentTaskId, deletingTask }) {
  let task = cloneDeep(rootTask);

  //if the date has a type, remove any dates and reminders that are relative
  if (task && (dateTypeId || deletingTask) && dateParentTaskId) {
    //delete dependent reminders
    task = deleteDependentReminders({ rootTask: task, dateTypeId, dateParentTaskId });

    let datesToDelete = [];
    //Find dates to delete from the root task
    const relDates = task.dates.filter(
      d =>
        d.relative &&
        (deletingTask || d.relative.base_type_id === dateTypeId) &&
        d.relative.base_task_id === dateParentTaskId
    );
    datesToDelete = [...datesToDelete, ...relDates.map(d => ({ ...d, dateParentTaskId: task.id }))];
    //Find dates to delete in subtasks
    if (task.subtasks) {
      task.subtasks.forEach(s => {
        if (!isClientRequest(s)) {
          const relDates = s.dates.filter(
            d =>
              d.relative &&
              (deletingTask || d.relative.base_type_id === dateTypeId) &&
              d.relative.base_task_id === dateParentTaskId
          );
          datesToDelete = [...datesToDelete, ...relDates.map(d => ({ ...d, dateParentTaskId: s.id }))];
        }
      });
    }
    //delete dates
    datesToDelete.forEach(d => {
      task = deleteDate({ rootTask: task, dateTypeId: d.date_type_id, dateParentTaskId: d.dateParentTaskId });
    });
  }
  return task;
}

//Delete reminders that are dependent on `date`
//Reminders can only be relative to dates on their own task (e.g. not any sibbling, children, or parent task dates)
function deleteDependentReminders({ rootTask, dateTypeId, dateParentTaskId }) {
  let task = cloneDeep(rootTask);

  if (task && dateTypeId && dateParentTaskId) {
    if (task.id === dateParentTaskId) {
      //reminder on rootTask
      task.reminders = task.reminders.filter(r => r.relationships.date_type_id !== dateTypeId);
    } else if (task.subtasks) {
      //find the task
      const parentTaskIndex = findIndex(task.subtasks, s => s.id === dateParentTaskId);
      const parentTask = task.subtasks[parentTaskIndex];
      if (parentTask.reminders) {
        //remove reminders on subtask
        parentTask.reminders = parentTask.reminders.filter(r => dateTypeId !== r.relationships.date_type_id);
        task.subtasks.splice(parentTaskIndex, 1, parentTask);
      }
    }
  }
  return task;
}
//element - the element to scroll to, or a function that gets the element to scroll to
export function scrollToElement(element, extraPaddingBottom = 16, extraPaddingTop = 0) {
  setTimeout(() => {
    if (typeof element === 'function') {
      element = element();
    }
    const container = document.getElementById('createTaskScrollArea');
    if (!container || !element) return;
    const visibleArea = container.clientHeight;
    const areaAbove = container.scrollTop;
    const pos = element.offsetTop;

    //Element is to low, scroll down
    if (pos + element.clientHeight + extraPaddingBottom - areaAbove > visibleArea) {
      container.scrollTop = pos - visibleArea + element.clientHeight + extraPaddingBottom - 64;
    }
    //Element is too high, scroll up
    else if (pos - extraPaddingTop < areaAbove) {
      container.scrollTop = pos - extraPaddingTop;
    }
  }, 100);
}

export function getUsedDateTypes(dateTypes, dates) {
  return dateTypes.filter(type => {
    return !!dates.find(date => type.id === date.date_type_id);
  });
}

export function addUsersToTemplates(templates, users) {
  users = users.map(u => ({ id: u.id, name: u.name }));
  return templates.map(t => ({ ...t, lastEditedBy: find(users, u => u.id === t.updated_by) }));
}

export function checkForClientRequest(task) {
  if (task.subtasks?.length) {
    return task.subtasks.some(sub => isClientRequest(sub));
  }
  return false;
}
