import './set-public-path.js';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { property } from 'lodash';
import singleSpaReact from 'single-spa-react';
import singleSpaCanopy from 'single-spa-canopy';
import StatusInputComponent from './common/status-input.component.js';
import StatusCell from './dashboard/table/status-cell.component.js';
import { reminderIntervalsMap } from './common/reminder-picker/reminder.constants.js';

// Single spa app functions
const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () => import(/*webpackChunkName: "tasks-app" */ './root.component.js').then(property('default')),
  domElementGetter,
});

const sscConfig = {
  domElementGetter,
  childAppName: 'tasks-ui',
  mainContentTransition: false,
  setPublicPath(publicPath) {
    __webpack_public_path__ = publicPath;
  },
};

const canopyLifecycles = singleSpaCanopy(sscConfig);

export const bootstrap = [canopyLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById('tasks-ui');
  if (!el) {
    el = document.createElement('div');
    el.setAttribute('id', 'tasks-ui');
    document.body.insertBefore(el, document.body.childNodes[0]);
  }
  return el;
}

// Code split version of createEditTaskModal
export function createEditTaskObservable() {
  return import(
    /* webpackChunkName: "createEditTaskObservable" */ './create-edit-task/create-edit-task.wrapper.js'
  ).then(property('createEditTaskModal'));
}

export async function getBulkClientRequestModal() {
  return import(
    /* webpackChunkName: "bulkClientRequestModal" */ './common/client-requests/client-request.component.js'
  ).then(mod => mod.ClientRequest);
}

//Temporarily exporting some components here.
//I'd like these to migrate to a single-spa parcels ASAP
//As we're dealing directly with tasks in notices and soon more places
//We're going to need a clean way to share these. Parcels is the long
//term solution, but in the short term I believe we need to do this
export const ReactStatusInput = StatusInputComponent;
export const ReactStatusInputCell = StatusCell;

export function getStatusInput() {
  return import(/* webpackChunkName: "statusInput" */ './common/status-input.component.js');
}

export function getTimeline() {
  return import(/* webpackChunkName: "timeline" */ './task-templates/timeline/timeline.component.js');
}

export const defaultIntervalMap = reminderIntervalsMap;

export function getReminderPicker() {
  return import(/* webpackChunkName: "reminderPicker" */ './common/reminder-picker/reminder-picker.component.js');
}

export function getTaskLink() {
  return import(/* webpackChunkName: "taskLink" */ './exports/task-link.component.js').then(mod => mod);
}

export function getTasksList() {
  return import(/* webpackChunkName: "tasks-list-component" */ './exports/tasks-list-component');
}

export function getCreateEditModal() {
  return import(
    /* webpackChunkName: "create-edit-modal-component" */ 'src/common/create-edit-modal/create-edit-modal.component.js'
  );
}

export function getInvalidAssigneesBanner() {
  return import(
    /* webpackChunkName: "invalid-assignees-banner-component" */ './common/invalid-asignees-resolve/invalid-assignees-banner.component'
  );
}

export function getFileUploader() {
  return import(
    /* webpackChunkName: "file-uploader-component" */ 'src/service-workspace/common/file-uploader.component.js'
  );
}

export function getStatusCell() {
  return import(/* webpackChunkName: "status-cell-component" */ 'src/dashboard/table/status-cell.component.js');
}

export function getBulkClientInfo() {
  return import(/* webpackChunkName: "bulk-client-info-component" */ 'src/common/bulk-client-info.component.js');
}

export { useBuildRoleAssignments } from './common/create-edit-modal/use-build-role-assignments.js';
